import desktopBg from 'src/static/assets/desktop-bg.jpg';
import mobileBg from 'src/static/assets/mobile-bg.jpg';
import searchIconSrc from 'src/static/assets/search-icon.svg';
import symbol1Src from 'src/static/assets/symbol_1.svg';
import symbol2Src from 'src/static/assets/symbol_2.svg';
import symbol3Src from 'src/static/assets/symbol_3.svg';
import fireFighterRoleIconSrc from 'src/static/assets/logo-fire-fighting.png';
import candleIcon from 'src/static/assets/candle-icon.svg';
import makoLogo from 'src/static/assets/mako-logo.png';
import facebook from 'src/static/assets/facebook.svg';
import twitter from 'src/static/assets/twitter.svg';
import whatsapp from 'src/static/assets/whatsapp.svg';
import toggle from 'src/static/assets/toggle.svg';
import shareToggle from 'src/static/assets/share-toggle.svg';
import advancedFiltersIcon from 'src/static/assets/advanced-filters.svg';
import makoLogoSvg from 'src/static/assets/mako-logo.svg';
import downloadIcon from 'src/static/assets/download-icon.svg';

const images = {
  desktopBg,
  mobileBg,
  searchIconSrc,
  symbol1Src,
  symbol2Src,
  fireFighterRoleIconSrc,
  candleIcon,
  makoLogo,
  facebook,
  twitter,
  whatsapp,
  toggle,
  shareToggle,
  advancedFiltersIcon,
  symbol3Src,
  makoLogoSvg,
  downloadIcon,
};

export default images;
