import React, { FC } from 'react';
// import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { useAppDispatch, useAppState } from 'src/Context';
import images from 'src/static/assets';
import { analytics } from 'src/utils';

import Shares from '../PersonModal/Shares';
import { ModalType } from 'src/utils/share';

import css from './VideoModal.module.scss';

interface Props {
  className?: string;
}

const VideoModal: FC<Props> = ({ className = '' }) => {
  const { selectedPerson, isVideoModalVisible } = useAppState();
  const dispatch = useAppDispatch();
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const [isGAVideoEventSent, setIsGAVideoEventSent] = React.useState(false);

  React.useEffect(() => {
    if (selectedPerson && isVideoModalVisible) {
      analytics.gtag.event('Murdered Details', {
        CUSTOM_PARAMETER: `${selectedPerson.b} ${selectedPerson.c}, ${selectedPerson.i}`,
      });
    }
  }, [selectedPerson, isVideoModalVisible]);

  const downloadFileUsingBlob = (url: string, filename: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename || 'downloaded-file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url); // Clean up
      })
      .catch((error) => console.error('File download failed:', error));
  };

  const onCloseBtnClick = () => {
    dispatch({ type: 'SET_IS_VIDEO_MODAL_VISIBLE', payload: false });
    dispatch({ type: 'SET_SELECTED_PERSON', payload: null });
    window.history.replaceState({}, '', '/');
  };

  const handlePlay = () => {
    if (videoRef.current && !isGAVideoEventSent) {
      analytics.gtag.event('Murdered Video Play', {
        CUSTOM_PARAMETER: `${selectedPerson.b} ${selectedPerson.c}, ${selectedPerson.i}`,
      });
      setIsGAVideoEventSent(true);
    }
  };

  return (
    <div className={cn(css.videoModal, className, !isVideoModalVisible && css.hidden)}>
      <div className={css.bg} onClick={onCloseBtnClick} />
      <div className={css.modalContent}>
        <button className={css['close-btn']} onClick={onCloseBtnClick}>
          <span />
          <span />
        </button>
        {selectedPerson && selectedPerson.m && (
          <>
            <div className={css.videoContainer}>
              <video
                controls
                playsInline
                preload="metadata"
                src={`${selectedPerson?.m}#t=0.0001` as string}
                ref={videoRef}
                onPlay={handlePlay}
              ></video>
            </div>
            <div className={css.buttons}>
              <button
                type="button"
                onClick={() =>
                  downloadFileUsingBlob(selectedPerson?.m as string, `${selectedPerson?.b}-${selectedPerson?.c}`)
                }
                className={css.downloadBtn}
              >
                <img src={images.downloadIcon} alt="download icon" />
              </button>
              {/* <a href={selectedPerson?.m as string} download className={css.downloadBtn} target="_blank">
                <img src={images.downloadIcon} alt="download icon" />
              </a> */}
              <Shares person={selectedPerson} modalType={'video' as ModalType} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VideoModal;
