import React, { FC } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppState } from 'src/Context';
import { thousandSeparator } from 'src/utils';
import { DEFAULT_IMAGE } from 'src/constants';
import { analytics } from 'src/utils';

import Shares from './Shares';
import { ModalType } from 'src/utils/share';

import css from './PersonModal.module.scss';

interface Props {
  className?: string;
}

const countTemplate = 'יחד הדלקנו יותר מ-{num} נרות';
const titleTemplate = 'הדלקתי נר לזכר {role} {name}';

const PersonModal: FC<Props> = ({ className = '' }) => {
  const { selectedPerson, isPersonModalVisible, candles } = useAppState();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (selectedPerson && isPersonModalVisible) {
      analytics.gtag.event('הדלקת נר', {
        CUSTOM_PARAMETER: `${selectedPerson.b} ${selectedPerson.c}, ${selectedPerson.i}`,
      });
    }
  }, [selectedPerson, isPersonModalVisible]);

  const onCloseBtnClick = () => {
    dispatch({ type: 'SET_IS_PERSON_MODAL_VISIBLE', payload: false });
  };

  return (
    <div className={cn(css.personModal, className, !isPersonModalVisible && css.hidden)}>
      <div className={css.bg} onClick={onCloseBtnClick} />
      <div className={css.modalContent}>
        <button className={css['close-btn']} onClick={onCloseBtnClick}>
          <span />
          <span />
        </button>

        <div className={css.photo}>
          <img
            src={(selectedPerson?.j as string) || DEFAULT_IMAGE}
            alt={`${selectedPerson?.a || ''} ${selectedPerson?.b || ''} ${selectedPerson?.c || ''}`
              .replace(/  +/g, ' ')
              .trim()}
          />
        </div>

        <div className={css.title}>
          {titleTemplate
            .replace('{name}', `${selectedPerson?.b || ''} ${selectedPerson?.c || ''}`)
            .replace('{role}', `${selectedPerson?.a}` || '')
            .replace(/  +/g, ' ')
            .trim()}
        </div>
        <div className={css.subtitle}> {selectedPerson?.i || ''}</div>

        <div className={css.countInfo}>{countTemplate.replace('{num}', `${thousandSeparator(candles.amount)}`)}</div>

        <Shares person={selectedPerson} modalType={'person' as ModalType} />
      </div>
    </div>
  );
};

export default PersonModal;
